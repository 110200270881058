import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import type { BottleCardProps } from "./Cards/BottleCard";
import BottleCardCarousel from "./Carousel/BottleCardCarousel";
import BottleCard from "./Cards/BottleCard";
import routes from "../../routes.json";

type tabContent = {
  code: string;
  title: string;
  bottles: BottleCardProps[];
}

interface TabsIndexesProps {
  tabsContent: tabContent[];
  locale: "it" | "en";
}


const TabsIndexes = ({ tabsContent, locale}: TabsIndexesProps) => (
  <Tabs defaultValue={tabsContent[0].code} className="flex flex-col justify-center max-w-full">
    <TabsList className="mt-10 gap-4">
      {tabsContent.map(tab => (
        <TabsTrigger
          className={`
            rounded-2xl
            px-4
            py-2
            border-2 border-secondary-400
            text-sm
            font-semibold
            hover:bg-secondary-300
            data-[state=active]:bg-primary-black
            data-[state=active]:text-primary-white
            data-[state=active]:border-primary-black
            data-[state=active]:hover:bg-secondary-700`}
          value={tab.code}
          key={`${tab.code}-trigger`}
        >
          {tab.title}
        </TabsTrigger>
      ))}
    </TabsList>
    {tabsContent.map(({code, bottles}) => (
      <TabsContent className="m-0" value={code} key={`${code}-content`}>
        <div className="flex w-full justify-center tablet:px-16 laptop:px-[90px]">
          <BottleCardCarousel
            carouselStyle="desktop:hidden laptop:hidden tablet:hidden w-full"
            items={bottles}
            carouselItemStyle="flex basis-1/2 justify-center my-2"
            locale={locale}
          />
          <div className="grid grid-cols-5 laptop:grid-cols-4 gap-6 mobile:hidden tablet:hidden py-16 tablet:py-8 mobile:py-8">
            {
              bottles.map((e: any) => (
                <BottleCard
                  name={e.name}
                  image={e.image}
                  producer={e.producer}
                  variation={e.variation}
                  tradingPrice={e.tradingPrice}
                  link={routes.SIGNUP}
                  locale={locale}
                />
              ))
            }
          </div>
          <div className="grid grid-cols-3 gap-6 mobile:hidden desktop:hidden laptop:hidden py-16 tablet:py-8 mobile:py-8">
            {
              bottles.map((e: any) => (
                <BottleCard
                  name={e.name}
                  image={e.image}
                  producer={e.producer}
                  variation={e.variation}
                  tradingPrice={e.tradingPrice}
                  link={routes.SIGNUP}
                  locale={locale}
                />
              ))
            }
          </div>
        </div>
      </TabsContent>
    ))}
  </Tabs>
);

export default TabsIndexes;